/* global $ */

class Traser {
  constructor() {
    this.$back = $('.Pointer--back');
    this.$front = $('.Pointer--front');

    this.mouseX = this.frontPosX = this.backPosX = 0;
    this.mouseY = this.frontPosY = this.backPosY = 0;

    this.mouseOutTimer  = undefined;
    this.isHover        = false;
    this.isStart        = false;
    this.startCount     = 0;

    $('a, button').on('mouseover', () => {
      clearInterval(this.mouseOutTimer);
      if(!this.isHover) {
        this.isHover = true;
        $('.Pointer').addClass('hover');
      }
    });

    $('a, button').on('mouseout', () => {
      clearInterval(this.mouseOutTimer);
      this.mouseOutTimer = setInterval(() => {
        if(this.isHover) {
          $('.Pointer').removeClass('hover');
          this.isHover = false;
        }
        clearInterval(this.mouseOutTimer);
      }, 50);
    });

    document.addEventListener('mousemove', (e) => {
      if(this.isStart) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
      } else {
        this.startCount++;
        if(this.startCount == 10) {
          this.mouseX = this.frontPosX = this.backPosX = e.clientX;
          this.mouseY = this.frontPosY = this.backPosY = e.clientY;
          this.$back.css('transform', `translate(${this.backPosX}px, ${this.backPosY}px)`);
          this.$front.css('transform', `translate(${this.frontPosX}px, ${this.frontPosY}px)`);
          $('.Pointer').addClass('fadein');
          this.isStart = true;
        }
      }

    });
    this.update();
  }
  update() {
    if(this.isStart) {
      this.frontPosX += (this.mouseX - this.frontPosX) / 3;
      this.frontPosY += (this.mouseY - this.frontPosY) / 3;

      this.backPosX += (this.mouseX - this.backPosX) / 6;
      this.backPosY += (this.mouseY - this.backPosY) / 6;

      this.$back.css('transform', `translate(${this.backPosX}px, ${this.backPosY}px)`);
      this.$front.css('transform', `translate(${this.frontPosX}px, ${this.frontPosY}px)`);
    }

    requestAnimationFrame(() => {
      this.update();
    });
  }
}
export default Traser;
