/* global $ */
import Vivus from 'vivus';
import Counter from './Counter';

class LazyLoader {
  constructor($el) {
    this.$el      = $el;
    this.loaded   = false;
    this.src      = this.$el.attr('data-original');

    this.$el.addClass('set-lazy');
    this.$el.one('appeer', () => {
      if(!this.loaded) {
        $('<img>')
          .one('load',() => {
            if(this.$el.prop('tagName') != 'IMG') {
              this.$el.css('background-image', 'url("' + this.src + '")');
            } else {
              this.$el.attr('src', this.src);
            }

            this.loaded = true;
            this.$el.addClass('loaded');
          })
          .attr('src', this.src);
      }
    });
  }

  fadein() {
    this.$el.trigger('appeer');
  }
}

class PageTitleEnText {
  constructor($el) {
    this.$el = $el;
  }

  fadein() {
    this.$el.addClass('fadein');
    setTimeout(() => {
      this.$el.addClass('out');
    }, 490);
  }
}

class PageTitle {
  constructor($el) {
    this.$el = $el;
    this.$en = this.$el.find('.PageTitleEn');
    this.hasCopy = false;

    if(this.$el.find('.PageTitle__copy').length) {
      this.hasCopy = true;
    }

    this.lines = [];

    if(this.$en.find('.PageTitleEn__line')) {
      this.$en.find('.PageTitleEn__line').each((i, e) => {
        this.lines.push(new PageTitleEnText($(e)));
      });
    }
  }

  fadein() {
    for(let i = 0; i < this.lines.length; i++) {
      setTimeout(() => {
        this.lines[i].fadein();
      }, 100 * i);
    }
    if(this.hasCopy) {
      setTimeout(() => {
        this.$el.find('.PageTitle__copy').css('opacity', 1);
      }, this.lines.length * 100 + 500);
    }
  }
}

class InviewObj {
  constructor($el) {

    this.$el            = $el;

    this.hasStrokeText  = false;
    this.hasPageTitle   = false;
    this.offsetTop      = -100;
    this.isLoaded       = false;
    this.delay          = 0;
    this.fadeinOffset   = 0;

    this.isDebug        = false;

    this.counter = undefined;
    this.lazyImage = undefined;
    this.isEffectImg = false;
    this.isWaitOnload = false;

    if(this.$el.attr('data-wait-onload') != undefined) {
      if(this.$el.attr('data-wait-onload') == 'true') {
        this.isWaitOnload = true;
        // console.log('this.isWaitOnload = true;');
      }
    }

    if(this.$el.attr('data-inview-offset') != undefined) {
      if(this.$el.attr('data-inview-offset') != '') {
        this.offsetTop = -parseInt(this.$el.attr('data-inview-offset'));
      }
    }

    if(this.$el.attr('data-fadein-delay') != undefined) {
      if(this.$el.attr('data-fadein-delay') != '') {
        this.delay = parseInt(this.$el.attr('data-fadein-delay'));
      }
    }

    if(this.$el.hasClass('PageTitle')) {
      this.hasPageTitle = true;
      this.pageTitle = new PageTitle(this.$el);
    }

    if(this.$el.hasClass('Counter')) {
      this.counter = new Counter(this.$el);
    }

    if(this.$el.hasClass('LazyImg')) {
      this.lazyImage = new LazyLoader(this.$el);
    }
    if(this.$el.hasClass('EffectImg') && this.$el.find('.ImgWrap__inner')) {
      if(this.$el.find('.ImgWrap__inner').attr('data-original') != undefined) {
        this.isEffectImg = true;
        this.lazyImage = new LazyLoader(this.$el.find('.ImgWrap__inner'));
      }
    }

    if(this.$el.find('.InviewObj').length == 0) {
      if(this.$el.find('.StrokeText').length) {
        this.hasStrokeText = true;

        let $_strokeText = this.$el.find('.StrokeText');
        let duration = 12;

        if($_strokeText.attr('data-stroke-duration') != undefined) {
          if($_strokeText.attr('data-stroke-duration') != '') {
            duration = parseInt($_strokeText.attr('data-stroke-duration'));
          }
        }

        this.strokeTextId = $_strokeText.find('.StrokeText__mask').attr('id');
        this.strokeText = new Vivus(this.strokeTextId, {
          type                : 'scenario-sync',
          duration            : duration,
          forceRender         : false,
          animTimingFunction  : Vivus.EASE,
          start               : 'manual'
        });
      }
    }
  }

  checkLoadImageStatus() {
    this.lazyImage.fadein();
    this.loadWaitInterval = setInterval(() => {
      if(this.lazyImage.loaded) {
        clearInterval(this.loadWaitInterval);
        this.$el.addClass('fadein');
      }
    }, 1000 / 30);
  }

  update(scrollTop, windowH) {
    if(this.isLoaded) return;
    if(scrollTop > this.$el.offset().top - windowH - this.offsetTop){

      setTimeout(() => {
        if(this.isEffectImg) {
          this.checkLoadImageStatus();
        } else {
          if(this.isWaitOnload) {
            this.loadWaitInterval = setInterval(() => {
              if(window.onLoadComplete) {
                clearInterval(this.loadWaitInterval);
                this.fadein();
              }
            }, 1000 / 30);
          } else {
            this.fadein();
          }

        }
      }, this.delay);

      this.isLoaded = true;
    }
  }

  fadein() {
    this.$el.addClass('fadein');

    if(this.lazyImage) {
      this.lazyImage.fadein();
    }

    if(this.counter) {
      this.counter.play();
    }
    if(this.hasPageTitle) {
      this.pageTitle.fadein();
    }
    if(this.hasStrokeText) {
      setTimeout(() => {
        this.strokeText.play();
      }, 300);
    }
  }
}

export default InviewObj;
