class RectBtn {
  constructor($el) {
    this.$el            = $el;

    this.currentHover   = 0;

    this.hoverSet = [
      '01', '02'
    ];
    this.hoverSet = this.shuffleArray(this.hoverSet);

    this.$el.attr('data-hover', this.hoverSet[0]);

    this.$el.mouseout(() => {
      this.changeHover();
    });
  }
  shuffleArray([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  changeHover() {
    this.currentHover += 1;
    if(this.currentHover == this.hoverSet.length) {
      this.currentHover = 0;
    }
    this.$el.attr('data-hover', this.hoverSet[this.currentHover]);
  }

}
export default RectBtn;
