import jQuery from 'jquery';
import 'jquery.easing';
import Accordion from './modules/Accordion';
import Hero from './modules/Hero';
import InviewObj from './modules/InviewObj';
import Menu from './modules/Menu';
import RectBtn from './modules/RectBtn';
import ViewMoreBtn from './modules/ViewMoreBtn';
import Card from './modules/Card';
import CreatorCard from './modules/CreatorCard';
import DropdownMenu from './modules/DropdownMenu';

import Swiper, { Navigation } from 'swiper';
import ScrollSwap from './modules/ScrollSwap';
import Traser from './modules/Traser';

const $ = jQuery;
window.jQuery = $;
window.$ = $;

window.onLoadComplete = false;


class MainApp {
  constructor() {
    this.$window          = $(window);
    this.fxImgSet         = [];
    this.menu             = new Menu(this);
    this.onLoadComplete   = false;

    if(!this.isTouchDevice()) {
      this.traser = new Traser();
    }


    let is_ie = false;
    if(navigator.userAgent.indexOf('msie') != -1 || navigator.userAgent.indexOf('trident') != -1) {
      is_ie = true;
    }
    if(is_ie) {
      console.log('Not support browser');

      if($('.Hero').length) {
        $('.Hero').remove();
        let div_splash = '<div class="Splash InviewObj Splash--business-01"><div class="SplashView"><div class="SplashView__center"><div class="SplashBottom"><div class="SplashEl SplashEl--business-01-02" data-index="01"><div class="SplashEl__mask"><div class="SplashEl__img"></div></div></div><div class="SplashEl SplashEl--business-01-01" data-index="02"><div class="SplashEl__mask"><div class="SplashEl__img"></div></div></div></div></div></div></div>';
        $('.Main--show-splash').replaceWith($(div_splash));
      }
      $('.Main').attr('class', 'Main');
      $('.Main > *').each((i, e) => {
        if($(e).hasClass('for-ie')) {
          $(e).css('display', 'block');
        } else {
          $(e).remove();
        }
      });
    } else {
      $('.for-ie').remove();
    }


    $('.Accordion').each((i, e) => {
      new Accordion($(e));
    });

    $('.InviewObj').each((i, e) => {
      this.fxImgSet.push(new InviewObj($(e)));
    });

    if($('.Hero').length) {
      this.hero = new Hero(this);
    }

    /**
     * グロナビ等からCOMPANYクリック時、既にトップページに居る場合はクリックイベントをキャンセルしアンカーリンクに挙動を変更
     */
    $('a').on('click', (e) => {
      if($('.Section--home-company').length) {
        let _href = $(e.currentTarget).attr('href');
        if(_href.indexOf('/#company') != -1) {

          if(this.menu.isOpen) this.menu.close();

          if(this.viewMode == 'sp') {
            setTimeout(() => {
              this.scrollPage(parseInt($('.Section--home-company').offset()['top']));
            }, 200);
          } else {
            this.scrollPage(parseInt($('.Section--home-company').offset()['top']));
          }
          return false;
        }
      }
    });

    if($('.ViewMoreBtn').length) {
      $('.ViewMoreBtn').each((i, e) => {
        new ViewMoreBtn($(e));
      });
    }

    if($('.RectBtn').length) {
      $('.RectBtn').each((i, e) => {
        new RectBtn($(e));
      });
    }

    if($('.Card').length) {
      $('.Card').each((i, e) => {
        new Card($(e));
      });
    }

    if($('.DropdownMenu').length) {
      $('.DropdownMenu').each((i, e) => {
        new DropdownMenu($(e));
      });
    }

    if($('.CreatorCard').length) {
      $('.CreatorCard').each((i, e) => {
        new CreatorCard($(e));
      });
    }

    if($('.ScrollSwap').length) {
      new ScrollSwap(this);
    }

    if($('.CreatorsArchive').length) {
      setTimeout(() => {
        $('.StickyBtn').css('margin-left', 0);
      }, 1000);
    }

    $('.js-showmore-creators').on('click', (e) => {
      $('.CreatorsListItem').css('display', 'block');
      $(e.currentTarget).parent().css('display', 'none');

    });

    $('.js-scrolldown').on('click', () => {
      this.scrollPage(parseInt($('#Section--home-production').offset()['top'] - 200));

    });

    $('.js-scroll').on('click', (e) => {
      if($(e.currentTarget).attr('data-target') != undefined) {
        if($('#' + $(e.currentTarget).attr('data-target')).length) {
          this.scrollPage(parseInt($('#' + $(e.currentTarget).attr('data-target')).offset()['top']));
        }
      } else {
        this.scrollPage(0);
      }
    });


    if($('.CreatorBusinessSlider').length) {
      // Creatorsトップ用
      new Swiper('.swiper', {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
          prevEl: '.BtnSwiperNav--prev',
          nextEl: '.BtnSwiperNav--next',
        },
        breakpoints: {
          // when window width is >= 320px
          900: {
            slidesPerView: 3,
            // spaceBetween: 20
          }
        }
      });
    }

    $(document).on('touchstart', 'a, button', (e) => {
      $(e.currentTarget).attr('data-touch', 'true');
    });

    $(document).on('mouseleave', 'a, button', (e) => {
      $(e.currentTarget).removeAttr('data-touch');
    });

    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ('scrollingElement' in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();

      });
    this.onWindowResize();

    setTimeout(() => {
      this.onLoadComplete = true;
    }, 1100);
    $('.Loading').delay(1000).animate({'opacity': 0}, 700, 'easeOutExpo', () => {
      $('.Loading').css('display', 'none');
    });
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }

  scrollPage(val, isCancelTransition = false) {
    if(isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement).stop(false).animate(
        {'scrollTop': val},
        1200,
        'easeInOutExpo'
      );
    }
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    if(this.windowHeight - $('.Footer').outerHeight(true) > $('main').height()) {
      $('.Footer').addClass('Footer--fixed');
    } else {
      $('.Footer').removeClass('Footer--fixed');
    }

    this.onWindowScroll();
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    for(let i = 0; i < this.fxImgSet.length; i++) {
      this.fxImgSet[i].update(this.scrollTop, this.windowHeight);
    }

    if($('.CreatorsArchive').length) {
      if(parseInt(this.scrollTop + (this.windowHeight / 2)) > $('.CreatorsArchive').offset()['top']) {
        $('.StickyBtn').addClass('hide');
      } else {
        $('.StickyBtn').removeClass('hide');
      }
    }
  }
}

$('document').ready(() => {
  new MainApp();


});


$(window).on('load', () => {
  window.onLoadComplete = true;
});
