/* global $ */

// import Swiper from "swiper";
import Swiper, { Pagination } from 'swiper';

class ScrollSwap {
  constructor(mainApp) {
    this.mainApp        = mainApp;
    this.$window        = $(window);
    this.$container     = $('.ScrollSwap');
    this.$blocks        = [];
    this.$navItems      = [];

    this.isFadein       = false;
    this.currentIndex   = 0;
    this.pastIndex      = 0;
    this.viewMode       = this.mainApp.menu.viewMode;
    this.pastViewMode   = '';//this.viewMode;


    $('.SwapContentNavListItem').each((i, e) => {
      this.$navItems.push($(e));
    });

    this.$container.find('.ScrollSwapBlock').each((i, e) => {
      this.$blocks.push($(e));
    });

    $('.SwapContentNavListItemBtn').on('click', (e) => {
      this.goto(parseInt($(e.currentTarget).attr('data-index')));
    });

    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ('scrollingElement' in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();

      });
    this.onWindowResize();
  }

  goto(target) {
    this.mainApp.scrollPage(parseInt(this.$blocks[target].offset()['top'] - this.windowHeightHalf + (this.blockHeight / 2)));
  }

  onWindowResize() {
    if(parseInt($('Menu').css('height')) == 0) {
      this.viewMode = 'sp';
    } else {
      this.viewMode = 'pc';
    }

    if(this.pastViewMode != this.viewMode) {
      if(this.viewMode == 'sp') {

        if(this.isFadein) {
          this.isFadein = false;
          $('.SwapContentNav')
            .stop(false)
            .animate({'opacity':0, 'margin-left': -20}, 300, 'easeOutExpo', () => {
              $('.SwapContentNav').css('display', 'none');
            });
        }

        // Business用
        this.swiper = new Swiper('.swiper', {
          modules: [Pagination],
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }
        });
      } else if(this.viewMode == 'pc') {
        if(this.swiper != undefined) {
          this.swiper.destroy();
          this.swiper = undefined;
        }
      }
    }
    this.pastViewMode = this.viewMode;

    this.windowWidth = this.$window.width();
    this.windowHeight = window.innerHeight;//this.$window.height();
    this.windowHeightHalf = Math.floor(this.windowHeight / 2);

    if(this.viewMode == 'pc') {
      this.containerTop = parseInt(this.$container.offset()['top']);
      this.containerHeight = parseInt(this.$container.height());
      this.blockHeight = parseInt($('.ScrollSwapBlock').height());

      $('.SwapContentNav').css('left', this.$container.offset()['left']);

      this.onWindowScroll();
    }
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if(this.viewMode == 'pc') {
      if(
        this.scrollTop + this.windowHeight - this.windowHeightHalf - 150 > this.containerTop
        && this.scrollTop + this.windowHeight - this.windowHeightHalf + 150 < this.containerTop + this.containerHeight
      ) {
        if(!this.isFadein) {
          this.isFadein = true;
          $('.SwapContentNav')
            .stop(false)
            .css({'display': 'block', 'margin-left': -20})
            .animate({'opacity':1, 'margin-left': 0}, 400, 'easeOutExpo');
        }
      } else {
        if(this.isFadein) {
          this.isFadein = false;
          $('.SwapContentNav')
            .stop(false)
            .animate({'opacity':0, 'margin-left': -20}, 300, 'easeOutExpo', () => {
              $('.SwapContentNav').css('display', 'none');
            });
        }
      }

      if(this.isFadein) {
        for(let i = 0; i < this.$blocks.length; i++) {
          if(this.scrollTop + this.windowHeight - this.windowHeightHalf > this.$blocks[this.$blocks.length - 1 - i].offset()['top']) {
            this.currentIndex = this.$blocks.length - 1 - i;
            break;
          }
        }

        if(this.pastIndex != this.currentIndex) {
          this.changeCurrentNav(this.currentIndex);
        }
        this.pastIndex = this.currentIndex;
      }
    }


  }
  changeCurrentNav(target) {
    for(let i = 0; i < this.$navItems.length; i++) {
      if(i == target) {
        this.$navItems[i].addClass('current');
      } else {
        this.$navItems[i].removeClass('current');
      }
    }
  }
}

export default ScrollSwap;
