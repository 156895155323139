/* global $ */

class Hero {
  constructor(mainApp) {
    this.mainApp      = mainApp;
    this.$window      = $(window);
    this.$container   = $('.Hero');
    this.$movie       = $('.HeroMovie');
    this.$video       = $('#video_hero');

    this.videoWidth   = 0;
    this.videoHeight  = 0;

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      });
    this.onWindowResize();

    let v = document.getElementById('video_hero');
    setTimeout(() => {
      v.play();
    }, 1000);

    this.onLoadCompleteInterval = setInterval(() => {
      if(this.mainApp.onLoadComplete) {
        clearInterval(this.onLoadCompleteInterval);
        this.$container.addClass('Hero--start');

        if(String(location.hash).length > 2) {
          if(location.hash == '#company') {
            this.mainApp.scrollPage(parseInt($('.Section--home-company').offset()['top']));
          }
        }

        setTimeout(() => {
          $('.HeroScrollDown').addClass('fadein');
        }, 900);
      }
    }, 1000 / 30);
  }
  onWindowResize() {
    let viewWidth = window.innerWidth;
    let baseHeight = parseInt(window.innerHeight * 1);

    if(viewWidth / window.innerHeight > 1.5) {
      baseHeight = parseInt(window.innerHeight * 1.15);
    }
    if(viewWidth < 750) {
      baseHeight = parseInt(window.innerHeight * 0.85);
    }

    this.$container.css('height', baseHeight);

    let viewHeight = parseInt($('.Main').offset()['top']);
    let viewRatio = viewWidth / viewHeight;
    let videoRatio = 16 / 9;
    let videoTop = 0;
    let videoLeft = 0;

    if(viewRatio < videoRatio) {
      this.videoHeight = viewHeight;
      this.videoWidth = this.videoHeight * videoRatio;
      videoTop = 0;
      videoLeft = -parseInt((this.videoWidth - viewWidth) / 2);
    } else {
      this.videoWidth = viewWidth;
      this.videoHeight = this.videoWidth / videoRatio;
      videoLeft = 0;
      videoTop = -parseInt((this.videoHeight - viewHeight) / 2);
    }

    this.$video.css({
      'width': this.videoWidth,
      'height': this.videoHeight,
      'top': videoTop,
      'left': videoLeft
    });
  }
}
export default Hero;
