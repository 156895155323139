class Card {
  constructor($el) {
    this.$el            = $el;

    this.currentHover   = 0;
    this.currentColor   = 0;

    this.hoverSet = [
      '01', '02'
    ];
    this.colorSet = [
      '01', '02', '03'
    ];
    this.hoverSet = this.shuffleArray(this.hoverSet);
    this.colorSet = this.shuffleArray(this.colorSet);

    this.$el.attr('data-hover', this.hoverSet[0]);
    this.$el.attr('data-color', this.colorSet[0]);

    if(!this.isTouchDevice()) {
      this.$el.mouseover(() => {
        clearInterval(this.mouseoutTimer);
      });

      this.$el.mouseout(() => {
        this.mouseoutTimer = setInterval(() => {
          clearInterval(this.mouseoutTimer);
          this.changeColor();
        }, 400);
        this.changeHover();
      });
    }

  }
  shuffleArray([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  changeHover() {
    this.currentHover += 1;
    if(this.currentHover == this.hoverSet.length) {
      this.currentHover = 0;
    }
    this.$el.attr('data-hover', this.hoverSet[this.currentHover]);
  }

  changeColor() {
    this.currentColor += 1;
    if(this.currentColor == this.colorSet.length) {
      this.currentColor = 0;
    }
    this.$el.attr('data-color', this.colorSet[this.currentColor]);
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }

}
export default Card;
