/* global $ */

class Counter {
  constructor($el) {
    this.$el            = $el;
    this.$base          = this.$el.find('.Counter__base');
    this.$action        = $('<div class="Counter__action"></div>');
    this.$el_after      = undefined;

    this.baseNumString  = this.$base.text();

    this.$el.append(this.$action);


    if(this.$el.attr('data-element-after') != undefined) {
      if(this.$el.attr('data-element-after') != '') {
        this.$el_after = $('.' + this.$el.attr('data-element-after'));
      }
    }

    this.actionStrings = [];

    for(let i = 0; i < this.baseNumString.length; i++) {
      let div = $(`<div><span>${this.baseNumString.slice(i, i + 1)}</span></div>`);
      this.actionStrings.push(div);
      this.$action.append(div);

    }

    this.baseNum = parseInt(this.baseNumString.split(',').join(''));

    this.actionNum = 0;
    this.isFadein = false;

    /*
    this.$el.on('click', () => {
      this.play();
    });
    */
  }
  play() {
    if(!this.isFadein) {
      // this.isFadein = true;

      for(let i = 0; i < this.actionStrings.length; i++) {
        setTimeout(() => {
          this.actionStrings[this.actionStrings.length - 1 - i].addClass('fadein');
        }, 70 * i);
      }
      if(this.$el_after) {
        setTimeout(() => {
          this.$el_after.addClass('fadein');
        }, 70 * this.actionStrings.length);
      }



      /*
      let obj = {'count': 0};

      this.update = setInterval(() => {
        console.log(obj.count);
        this.$action.text(parseInt(obj.count).toLocaleString());
      }, 1000 / 60);

      $(obj).animate({'count': this.baseNum}, {'duration': 5000, 'easing': 'easeInExpo', 'step': (s) => {
      }, 'complete': () => {
        console.log('comp');
        this.$action.text(this.baseNum.toLocaleString());
        clearInterval(this.update);
      }});
      */
    }
  }
}

export default Counter;
