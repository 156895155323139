class ViewMoreBtn {
  constructor($el) {
    this.$el            = $el;
    this.$before        = this.$el.find('.ViewMoreBtnImgHover__before');
    this.$after         = this.$el.find('.ViewMoreBtnImgHover__after');

    this.currentColor   = 0;

    this.colorSet = [
      '01', '02', '03'
    ];
    this.colorSet = this.shuffleArray(this.colorSet);

    this.$el.attr('data-color', this.colorSet[0]);

    if(!this.isTouchDevice()) {
      this.$el.mouseover(() => {
        this.$before.stop(false).css({'left': '-100%'}).animate({'left': '0'}, 210, 'easeOutExpo');

        setTimeout(() => {
          this.$after.stop(false).css({'opacity': 0, 'left': 0}).delay(10).animate({'opacity':1}, 200, 'easeInOutSine', () => {
            this.$before.css({'left': '100%'});
            this.$after.delay(10).animate({'left': '100%'}, 380, 'easeOutExpo', () => {
              this.changeColor();
            });
          });
        }, 210);

      });
    }
  }

  shuffleArray([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  changeColor() {
    this.currentColor += 1;
    if(this.currentColor == this.colorSet.length) {
      this.currentColor = 0;
    }
    this.$el.attr('data-color', this.colorSet[this.currentColor]);
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }
}
export default ViewMoreBtn;
