class Accordion {
  constructor($el) {
    this.$container         = $el;
    this.$header            = this.$container.find('.AccordionHeader');
    this.$contents          = this.$container.find('.AccordionContents');
    this.$contents_inner    = this.$container.find('.AccordionContents__inner');

    this.isOpen             = false;

    this.$header.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });
  }

  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$container.addClass('Accordion--open');
      let _contentsHeight = this.$contents_inner.outerHeight(true);
      this.$contents.stop(false).animate({'height': _contentsHeight}, 400, 'easeOutExpo', () => {
        this.$contents.css({'height': 'auto', 'overflow': 'visible'});
      });
    }
  }

  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$container.removeClass('Accordion--open');
      let _contentsHeight = this.$contents.height();
      this.$contents.stop(false).css({'heihgt': _contentsHeight, 'overflow': 'hidden'}).animate({'height': 0}, 400, 'easeOutExpo');
    }
  }
}
export default Accordion;
