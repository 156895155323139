class DropdownMenu {
  constructor($el) {
    this.$el      = $el;
    this.$wrap    = this.$el.find('.DropdownMenu__collapse');
    this.$list    = this.$el.find('.DropdownMenuList');
    this.$btn     = this.$el.find('.DropdownMenuToggleBtn');

    this.isOpen   = false;

    this.$btn.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });
  }

  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$el.addClass('DropdownMenu--open');
      let openHeight = this.$list.outerHeight();
      this.$wrap.stop(false).animate({'height': openHeight}, 300, 'easeOutExpo');
    }
  }

  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$el.removeClass('DropdownMenu--open');
      this.$wrap.stop(false).animate({'height': 0}, 400, 'easeOutExpo');
    }
  }
}
export default DropdownMenu;
