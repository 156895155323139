/* global $ */

import PerfectScrollbar from 'perfect-scrollbar';

class MenuListItemBtn {
  constructor($el) {
    this.$el                        = $el;
    this.$hover                     = this.$el.find('.MenuListItemBtnHover');
    this.$hover_inner               = this.$hover.children('.MenuListItemBtnHover__inner');

    this.hoverTimer                 = undefined;
    this.isMouseOver                = false;
    this.isExecuteMouseoverEffect   = false;
    this.isExecuteMouseoutEffect    = false;
    this.onCompleteMouseoverEffect  = false;
    this.currentColor               = 0;

    this.colorSet = [
      '01', '02', '03'
    ];
    this.colorSet = this.shuffleArray(this.colorSet);

    this.$el.attr('data-color', this.colorSet[0]);

    if(!this.isTouchDevice()) {
      this.$el.mouseover(() => {
        this.isMouseOver = true;
        if(!this.isExecuteMouseoutEffect) {
          this.isExecuteMouseoverEffect = true;
          this.onCompleteMouseoverEffect = false;
          this.$hover_inner.css({'left': 0, 'right': 'auto'});
          this.$hover.stop(false).css({'left': 0, 'right': 'auto'}).animate({'width': '100%'}, 250, 'easeOutExpo', () => {
            this.isExecuteMouseoverEffect = false;
            this.onCompleteMouseoverEffect = true;
            if(!this.isMouseOver) {
              this.mouseOut();
            }
          });
        } else {
          this.$hover_inner.stop(false).css({'left': 0, 'right': 'auto'});
          this.$hover.stop(true, false).css({'left': 0, 'right': 'auto', 'width': '100%'});
        }
      });


      this.$el.mouseout(() => {
        this.isMouseOver = false;
        if(!this.isExecuteMouseoverEffect && this.onCompleteMouseoverEffect) {
          this.isExecuteMouseoutEffect = true;
          this.mouseOut();
        }
      });
    }


  }
  mouseOut() {
    if(!this.isMouseOver) {
      this.$hover.stop(false).css({'right': 0, 'left': 'auto'});
      this.$hover_inner.stop(false).css({'left': 'auto', 'right': 0});
      this.$hover.animate({'width': 0}, 300, 'easeInExpo', () => {
        this.$hover.css({'left': 0, 'right': 'auto', 'width': 0});
        this.$hover_inner.css({'left': 0, 'right': 'auto'});
        this.changeColor();
        this.isExecuteMouseoutEffect = false;
      });
    }
  }

  shuffleArray([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  changeColor() {
    this.currentColor += 1;
    if(this.currentColor == this.colorSet.length) {
      this.currentColor = 0;
    }
    this.$el.attr('data-color', this.colorSet[this.currentColor]);
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }
}

class Menu {
  constructor(mainApp) {
    this.mainApp      = mainApp;
    this.$window      = $(window);
    this.$container   = $('Menu');
    this.$btn         = $('.MenuToggleBtn');
    this.$slider      = $('.MenuSlider');
    this.$bg          = $('.Menu__bg');

    this.isOpen       = false;
    this.viewMode = 'pc';

    if(parseInt(this.$container.css('height')) == 0) {
      this.viewMode = 'sp';
    }

    this.$container.find('.MenuListItemBtn').each((i, e) => {
      new MenuListItemBtn($(e));
    });

    new PerfectScrollbar('#MenuInnerScroller', {
      suppressScrollX: true
    });

    this.$btn.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });

    this.$bg.on('click', () => {
      this.close();
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      });
    this.onWindowResize();
  }

  onWindowResize() {
    if(parseInt(this.$container.css('height')) == 0) {
      this.viewMode = 'sp';
      this.$slider.css('height', window.innerHeight);
      this.openWidth = 270;
    } else {
      this.viewMode = 'pc';
      this.$slider.css('height', '100%');
      this.openWidth = 383;
    }
  }

  offTouchEvent(e) {
    e.preventDefault();
  }

  open() {
    if(!this.isOpen) {
      if(this.viewMode == 'sp') {
        document.addEventListener('touchmove', this.offTouchEvent, {passive: false});
        this.tmpScrollTop = this.mainApp.scrollTop;
        $('html').css('overflow-y', 'hidden');
      }

      this.$container.addClass('Menu--open');
      this.isOpen = true;
      this.$slider.stop(false).animate({'width': this.openWidth, 'left': -this.openWidth}, 310, 'easeOutExpo');
      this.$bg.stop(false).css({'display': 'block', 'opacity': 0}).animate({'opacity': 1}, 310, 'easeOutExpo');
    }
  }

  close() {
    if(this.isOpen) {
      this.$container.removeClass('Menu--open');

      if(this.viewMode == 'sp') {
        document.removeEventListener('touchmove', this.offTouchEvent);
        $('html').css('overflow-y', 'visible');
        this.mainApp.scrollPage(this.tmpScrollTop, true);
      }


      this.isOpen = false;
      this.$slider.stop(false).animate({'width': 0, 'left': 0}, 400, 'easeOutExpo');
      this.$bg.stop(false).animate({'opacity': 0}, 350, 'easeOutExpo', () => {
        this.$bg.css('display', 'none');
      });
    }
  }
}

export default Menu;
